import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import {
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
} from '../../../sharedComponents';
import { LogosContent } from '../../innerComponents';

export const RowLayout = (props) => {
  const {
    section: { categoryId, style, link, text },
    category,
    categoryHeading,
  } = props;
  const { organisations = [], blurb, logoSize = 'MEDIUM' } = category;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-3">
          <StyledHeading content={categoryHeading} displayStyles={dStyles} />
          <StyledParagraph
            content={categoryId === 'ALL' ? blurb : text || blurb}
            displayStyles={dStyles}
          />
        </div>
        <div className="col-xs-12 col-sm-9">
          <LogosContent
            logoSize={logoSize}
            organisations={organisations}
            style={style}
          />
        </div>
      </div>
      {link?.type && (
        <div className="row">
          <div className="col-xs-12">
            <StyledCallToAction ctaConfig={link} displayStyles={dStyles} />
          </div>
        </div>
      )}
    </div>
  );
};

RowLayout.propTypes = {
  section: PropTypes.shape({
    sponsorsCategories: PropTypes.array,
    categoryId: PropTypes.string,
    style: PropTypes.string,
    layout: PropTypes.string,
    link: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  category: PropTypes.object,
  categoryHeading: PropTypes.string,
};
