import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

import { StyledCallToAction } from '../../../sharedComponents';

const Wrapper = styled.div`
  height: 100%;
  a {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.category?.ctaWrapper,
      )}
  }
`;

export function CTAWrapper(props) {
  const {
    organisation: {
      linkToExternalPageEnabled,
      url,
      path,
      openInNewTabEnabled,
    } = {},
    children,
  } = props;

  return (
    <Wrapper>
      <StyledCallToAction
        type="link"
        sitePath
        to={linkToExternalPageEnabled ? url : `/sponsors/${path}`}
        target={
          openInNewTabEnabled && linkToExternalPageEnabled ? '_blank' : null
        }
        displayStyles={{ elements: { callToAction: { height: '100%' } } }}
      >
        {children}
      </StyledCallToAction>
    </Wrapper>
  );
}

CTAWrapper.propTypes = {
  organisation: PropTypes.shape({
    linkToExternalPageEnabled: PropTypes.bool,
    url: PropTypes.string,
    path: PropTypes.string,
    openInNewTabEnabled: PropTypes.bool,
  }),
};
