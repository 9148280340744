import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { renderStyledElementStyles } from '../../helpers';

import { SiteBuilderModule } from '../sharedComponents';
import { DefaultLayout, RowLayout } from './layoutStyles';
import ExhibitorListings from './displayStyles/ExhibitorListings';
import ScrollingLogos from './displayStyles/ScrollingLogos';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.spexCategoryModule,
    )}
  ${(props) =>
    props.isHomePage &&
    renderStyledElementStyles(
      props.theme,
      props.theme.siteBuilderModules.spexCategoryModule.visualStyles?.home,
    )}
  ${(props) =>
    !props.isHomePage &&
    renderStyledElementStyles(
      props.theme,
      props.theme.siteBuilderModules.spexCategoryModule.visualStyles
        ?.otherPages,
    )}
`;

const SPEXCategoryModule = (props) => {
  const {
    section: {
      sponsorsCategories = [],
      style,
      layout,
      title: outerTitle,
      name: outerName,
    },
    section,
    pageConfig: { siteSubPath },
  } = props;
  const themeConfig = useContext(ThemeContext);
  const dStyles =
    themeConfig.siteBuilderModules.spexCategoryModule.displayStyles[style];

  if (style === 'EXHIBITOR_LISTINGS') {
    return (
      <SiteBuilderModule section={section} moduleTheme={{ dStyles }}>
        <ExhibitorListings section={section} />
      </SiteBuilderModule>
    );
  }

  if (style === 'SCROLLING_LOGOS') {
    return (
      <SiteBuilderModule section={section} moduleTheme={{ dStyles }}>
        <ScrollingLogos section={section} />
      </SiteBuilderModule>
    );
  }

  return sponsorsCategories.map((category, index) => {
    const categoryHeading = category.title || outerTitle || outerName;

    return (
      <SiteBuilderModule
        moduleTheme={{ dStyles }}
        section={section}
        key={index}
      >
        <Wrapper isHomePage={!siteSubPath}>
          {layout === 'ROW_MODE' ? (
            <RowLayout
              section={props.section}
              categoryHeading={categoryHeading}
              category={category}
            />
          ) : (
            <DefaultLayout
              section={props.section}
              categoryHeading={categoryHeading}
              category={category}
            />
          )}
        </Wrapper>
      </SiteBuilderModule>
    );
  });
};

SPEXCategoryModule.propTypes = {
  section: PropTypes.shape({
    sponsorsCategories: PropTypes.array,
    categoryId: PropTypes.string,
    style: PropTypes.string,
    layout: PropTypes.string,
    link: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  pageConfig: PropTypes.shape({
    siteSubPath: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(SPEXCategoryModule);
