import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CTAWrapper, Logo } from '../../innerComponents';
import Carousel from '../../../../../shared/Carousel/Carousel';
import styled, { ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { getOrganisationsWithLogos, getUniqOrganisations } from './helpers';
import { StyledHeading } from '../../../sharedComponents';

const carouselSettings = {
  arrows: false,
  dots: false,
  autoplay: true,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

const scrollSingleItemSettings = {
  ...carouselSettings,
  speed: 2000,
  autoplaySpeed: 2000,
};

const smoothScrollingSettings = {
  ...carouselSettings,
  speed: 3000,
  autoplaySpeed: 0,
  cssEase: 'linear',
};

const scrollPageSettings = {
  ...carouselSettings,
  slidesToScroll: 5,
  speed: 1000,
  autoplaySpeed: 4000,
  responsive: carouselSettings.responsive.map((responsive) => ({
    ...responsive,
    settings: {
      ...responsive.settings,
      slidesToScroll: responsive.settings.slidesToShow,
    },
  })),
};

const Wrapper = styled.div`
  .container,
  .container-fluid {
    padding: 0;
  }

  overflow: hidden;
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)};
`;

const CustomTitle = styled.div`
  margin-bottom: 40px;
  text-align: center;
`;

export default function ScrollingLogos(props) {
  const {
    section: {
      displayStyle,
      layoutType,
      rotateType,
      titleType,
      customTitle,
      sponsorsCategories,
    },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles =
    themeConfig.siteBuilderModules.spexCategoryModule.displayStyles[
      displayStyle
    ];

  const organisations = getUniqOrganisations(sponsorsCategories);
  const organisationsWithLogos = getOrganisationsWithLogos(organisations);

  const embedSettings = useMemo(() => {
    switch (rotateType) {
      case 'SINGLE':
        return scrollSingleItemSettings;

      case 'SMOOTH':
        return smoothScrollingSettings;

      case 'PAGE':
        return scrollPageSettings;

      default:
        return scrollSingleItemSettings;
    }
  }, [rotateType]);

  const layoutClassName =
    layoutType === 'FULLSCREEN' ? 'container-fluid' : 'container';

  return (
    <Wrapper>
      {titleType === 'CUSTOM' && customTitle && (
        <CustomTitle>
          <StyledHeading content={customTitle} displayStyles={dStyles} />
        </CustomTitle>
      )}
      <div className={layoutClassName}>
        <Carousel embedSettings={embedSettings}>
          {organisationsWithLogos.map((organisation, index) => (
            <CTAWrapper organisation={organisation} key={index}>
              <Logo logoPath={organisation.logo.path} />
            </CTAWrapper>
          ))}
        </Carousel>
      </div>
    </Wrapper>
  );
}

ScrollingLogos.propTypes = {
  section: PropTypes.shape({
    displayStyle: PropTypes.string,
    layoutType: PropTypes.string,
    rotateType: PropTypes.string,
    titleType: PropTypes.string,
    customTitle: PropTypes.string,
    sponsorsCategories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        logoSize: PropTypes.string,
        organisations: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string,
            url: PropTypes.string,
            location: PropTypes.string,
            featured: PropTypes.bool,
            openInNewTabEnabled: PropTypes.bool,
            linkToExternalPageEnabled: PropTypes.bool,
            sponsorTitle: PropTypes.string,
            logo: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
        ),
      }),
    ),
  }),
};
